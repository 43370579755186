import logo from './logo.svg';

function App() {
      return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%', background: '#E6E6E7' }}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={logo} width='80px' alt="logo" />
                        <text style={{ fontSize: '60px', marginLeft: 14, marginBottom: 4 }}>C3</text>
                  </div>
                  <text style={{ marginTop: '4px', }}>Caring Code Creation</text>
                  <div style={{ display: 'flex', borderRadius: 50, alignItems: 'center', padding: '2px 40px', background: '#9F9F9F', marginTop: '10px' }}>
                        <text style={{ fontSize: '10px', color: 'white', marginBottom: 2 }}>Launching Soon</text>
                  </div>
            </div>
  );
}

export default App;
